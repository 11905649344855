<template>
  <div>
    <div>
      <b-form
        v-if="items"
        id="form"
        ref="form"
        class="margin-form"
        @submit.prevent="repeateAgain"
      >
        <!-- Row Loop -->
        <b-row
          v-for="(item, index) in items"
          :id="item.id"
          :key="item.id"
          ref="row"
        >

          <!--  NUM -->
          <b-col
            md="1"
            sm="1"
          >
            <b-form-group
              label="#"
              :label-for="'numero-'+item.id"
              class="text-center"
            >
              <b-form-input
                :id="'numero-'+item.id"
                v-model="item.id"
                class="text-center"
                type="number"
                plaintext
              />
            </b-form-group>
          </b-col>

          <!-- Item Name -->
          <b-col
            md="3"
          >
            <b-form-group
              label="Producto"
              :label-for="'producto-'+item.id"
            >
              <v-select
                :id="'producto-'+item.id"
                v-model="item.idProducto"
                input-id="idProducto"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="getProductos"
                label="text"
                :reduce="option => option.id"
                :getOptionLabel="option => option.codigoNombre"
                placeholder="Seleccione un producto"
                :selectable="option => option.disabled === true ? false : true"
                deselect="false"
                :disabled="item.disabled"
                @input="seleccionaProducto(item)"
              >
                <template
                  #option="{ codigo, nombre, imagen }"
                  disabled
                >
                  <b-avatar
                    size="32"
                    :src="imagen"
                    :text="avatarText(nombre)"
                    class="mr-1"
                  />
                  <span> {{ codigo }} - {{ nombre }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>

          <!-- Unidad -->
          <b-col
            lg="1"
            md="1"
          >
            <b-form-group
              label="Unidad"
              :label-for="'unidad-'+item.id"
              class="text-center"
            >
              <p
                v-if="item.unidad"
                class="card-text mt-1 text-center"
              >
                {{ item.unidad }}
              </p>
              <p
                v-else
                class="card-text mt-1 text-center"
              >
                -
              </p>
            </b-form-group>
          </b-col>

          <!-- Cantidad -->
          <b-col
            md="1"
          >
            <b-form-group
              label="Cantidad"
              :label-for="'cantidad-'+item.id"
            >
              <cleave
                :id="'cantidad-'+item.id"
                v-model="item.cantidad"
                class="form-control"
                :raw="false"
                :options="options.number"
                :disabled="item.idProducto == null"
                @input="item.cantidad = soloNumeros(item.cantidad), enterCantidad(item)"
              />
            </b-form-group>
          </b-col>

          <!-- Valor Unitario -->
          <b-col
            lg="2"
            md="1"
          >
            <b-form-group
              label="Valor unitario"
              :label-for="'valorUnitario-'+item.id"
            >
              <b-input-group
                prepend="$"
              >
                <cleave
                  :id="'valorUnitario-'+item.id"
                  v-model="item.valorUnitario"
                  class="form-control"
                  :options="options.number"
                  :disabled="item.idProducto == null"
                  @input="item.valorUnitario = soloNumeros(item.valorUnitario), enterValorUnitario(item)"
                />
              </b-input-group>

            </b-form-group>
          </b-col>

          <!-- Descuento -->
          <b-col
            lg="2"
            md="1"
          >
            <b-form-group
              v-if="permisoDescuento"
              label="Descuento"
              :label-for="'descuento-'+item.id"
            >
              <b-input-group
                prepend="$"
              >
                <cleave
                  :id="'descuento-'+item.id"
                  v-model="item.descuento"
                  class="form-control"
                  :raw="false"
                  :options="options.number"
                  :disabled="item.idProducto == null"
                  @input="item.descuento = soloNumeros(item.descuento), enterDescuento(item)"
                />
              </b-input-group>
            </b-form-group>
            <b-form-group
              v-else
              label="Descuento"
              :label-for="'descuento-'+item.id"
            >
              <b-input-group
                prepend="$"
              >
                <cleave
                  :id="'descuento-'+item.id"
                  :value="item.descuento"
                  class="form-control"
                  :raw="false"
                  :options="options.number"
                  :disabled="true"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- Valor Total -->
          <b-col
            lg="1"
            md="1"
          >
            <b-form-group
              label="Valor total"
              :label-for="'valorTotal-'+item.id"
            >
              <p class="card-text text-primary mt-1">
                {{ formatoPeso(item.valorTotal) }}
              </p>
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col
            lg="1"
            md="1"
            class="mb-50 text-left"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2 text-md-left mr-2 mb-sm-1 btn-sm-block"
              size="sm"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span />
            </b-button>
          </b-col>
          <colLinea
            class="margin-hr"
            ancho="md"
          />
        </b-row>
      </b-form>
    </div>
    <b-row>
      <b-col
        lg="9"
        md="9"
      >
        <b-button
          class="mt-2 ml-md-2 btn-sm-block"
          variant="primary"
          @click="repeateAgain"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-25"
          />
          <span />
        </b-button>
      </b-col>
      <netoIvaTotal
        :valorNeto="valorTotalOc"
      />
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BAvatar, BInputGroup,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import { formatos } from '@/mixins/forms'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import { mapActions, mapGetters } from 'vuex'

import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

const colLinea = () => import('@/layouts/components/Recycled/Form/colLinea.vue')
const netoIvaTotal = () => import('@/layouts/components/Recycled/Form/netoIvaTotal.vue')

export default {
  components: {
    vSelect,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BAvatar,
    BInputGroup,
    Cleave,

    colLinea,
    netoIvaTotal,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition, formatos],
  props: {
    comprasProductos: {
      type: Array,
      required: true,
    },
    cantidadesTotal: {
      type: Number(0),
      default: false,
    },
    valorTotal: {
      type: Number,
      required: true,
    },
    update: {
      type: Boolean,
      default: false,
    },
    cargando: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      nextTodoId: 1,
      valorTotalOc: 0,
      options: {
        number: {
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    ...mapGetters({
      getProductos: 'productos/getProductos',
      getUser: 'auth/user',
    }),
    permisoDescuento() {
      let result = false
      if (this.getUser.tipoUsuario === 'Admin'
        || this.getUser.tipoUsuario === 'Bodeguero') {
        result = true
      }
      return result
    },
  },
  watch: {
    items() {
      this.$emit('update:comprasProductos', this.items)
      this.setProductoDisabled()
    },
    getProductos() {
      this.setItemsId()
      this.setProductoDisabled()
    },
  },
  mounted() {
    this.initTrHeight()
    this.fetchProductos()
    this.items = this.comprasProductos
    this.setValorTotal()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    ...mapActions({
      fetchProductos: 'productos/fetchProductos',
    }),
    getItemIndex(id) {
      return Number(id) - Number(1)
    },
    enterCantidad(item) {
      if (item.id <= this.items.length) {
        this.setValorTotalProducto(item)
        this.setCantidadTotal()
      }
    },
    enterValorUnitario(item) {
      if (item.id <= this.items.length) {
        this.setValorTotalProducto(item)
      }
    },
    enterDescuento(item) {
      if (item.id <= this.items.length) {
        this.setValorTotalProducto(item)
      }
    },
    seleccionaProducto(item) {
      if (item.idProducto !== null) {
        this.setProductoDisabled()
        this.setCantidad(item, 0)
        this.setDescuento(item, 0)
        this.setValorUnitarioProducto(item, 0)
      } else {
        this.setProductoDisabled()
        this.setCantidad(item, 0)
        this.setDescuento(item, 0)
        this.setValorUnitarioProducto(item, 0)
        this.setValorTotalProducto(item, 0)
      }
    },
    getProducto(id) {
      const productoFind = this.getProductos.find(producto => producto.id === Number(id))
      return productoFind
    },
    setProductoDisabled() {
      this.getProductos.forEach(getProducto => {
        const findGetProductoEach = this.getProducto(getProducto.id)
        findGetProductoEach.disabled = false
      })
      this.items.forEach((itemEach, i) => {
        if (itemEach.idProducto !== null) {
          const findProductoEach = this.getProducto(itemEach.idProducto)
          if (typeof findProductoEach !== 'undefined') {
            findProductoEach.disabled = true
            this.setUnidad(i, findProductoEach.unidad)
          }
          this.items[i].nuevo = true
        } else {
          this.setUnidad(i, null)
        }
      })
    },
    setUnidad(index, unidad) {
      if (unidad) {
        this.items[index].unidad = unidad
      } else {
        this.items[index].unidad = null
      }
    },
    setCantidad(item, cantidad) {
      const index = this.getItemIndex(item.id)
      this.items[index].cantidad = cantidad
    },
    setDescuento(item, descuento) {
      const index = this.getItemIndex(item.id)
      this.items[index].descuento = descuento
    },
    setValorUnitarioProducto(item, valorUnitario) {
      const index = this.getItemIndex(item.id)
      this.items[index].valorUnitario = Number(valorUnitario)
    },
    getDescuentoValorUnitario(item, valorUnitario) {
      let valorUnitarioFinal = valorUnitario
      if (Number(item.descuento) > 0 && Number(item.descuento) <= valorUnitario) {
        valorUnitarioFinal = Number(valorUnitario) - Number(item.descuento)
      } else if (Number(item.descuento) > Number(valorUnitario)) {
        this.setDescuento(item, Number(valorUnitario))
        valorUnitarioFinal = 0
      }
      return valorUnitarioFinal
    },
    getValorTotalProducto(item) {
      let valorTotalProducto = 0
      if (Number(item.cantidad) < 0 || Number(item.cantidad) === 0 || Number(item.valorUnitario) < 0 || Number(item.valorUnitario) === 0) {
        valorTotalProducto = Number(0)
      } else if (Number(item.cantidad) > 0 && Number(item.valorUnitario) > 0) {
        const valorUnitario = this.getDescuentoValorUnitario(item, item.valorUnitario)
        valorTotalProducto = Number(valorUnitario) * Number(item.cantidad)
      }
      return valorTotalProducto
    },
    setValorTotalProducto(item) {
      const index = this.getItemIndex(item.id)
      const getValorTotalProducto = this.getValorTotalProducto(item)
      this.items[index].valorTotal = getValorTotalProducto
      this.setValorTotal()
    },
    getValorTotal() {
      let valorTotal = 0
      this.items.forEach(item => {
        valorTotal = Number(valorTotal) + Number(item.valorTotal)
      })
      this.valorTotalOc = Number(valorTotal)
      return valorTotal
    },
    setValorTotal() {
      const valorTotal = this.getValorTotal()
      this.$emit('update:valorTotal', valorTotal)
    },
    getCantidadTotal() {
      let cantidad = 0
      this.items.forEach(item => {
        cantidad = Number(cantidad) + Number(item.cantidad)
      })
      return Number(cantidad)
    },
    setCantidadTotal() {
      const cantidad = this.getCantidadTotal()
      this.$emit('update:cantidadesTotal', cantidad)
    },
    // Vuexy
    setItemsId() {
      this.items.forEach((item, i) => {
        this.items[i].id = i + 1
        this.items[i].idProducto = Number(item.idProducto)
      })
      this.$emit('update:cargando', false)
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += 1,
        idProducto: null,
        unidad: null,
        cantidad: 0,
        valorUnitario: 0,
        descuento: 0,
        valorTotal: 0,
        disabled: false,
      })
      this.setItemsId()
      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
        const scrollAlFinal = document.getElementById('form')
        scrollAlFinal.scrollTop = '9999'
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.setItemsId()
      this.setValorTotal()
      this.setProductoDisabled()
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.margin-hr {
  margin-top: -1px;
}
.margin-form {
  margin-top: 8px;
}
</style>
